<template>
  <layout>
    <template #title>
      <slot />
    </template>
    <v-container>
      <slot name="before" />
      <documents-loader
        :url="url"
        :filter-id="areaId"
        :disabled="!areaId"
        :update.sync="update"
      />
      <slot name="after" />
    </v-container>
  </layout>
</template>

<script>
import Layout from '@/views/project/layouts/Layout'
import DocumentsLoader from '@/components/Document/DocumentsLoader'

export default {
  name: 'AreaDocumentsLayout',
  components: { DocumentsLoader, Layout },
  props: {
    url: {
      type: String,
      default: '',
      required: true
    }
  },
  data: () => ({
    update: false
  }),
  computed: {
    areaId () {
      return this.$store.getters['project/areaId']
    }
  },
  watch: {
    areaId () {
      this.update = true
    }
  },
  mounted () {
    this.update = true
  }
}
</script>
