<template>
  <v-col cols="12" xl="10" offset-xl="1" class="position-relative">
    <slot name="title" />
    <div>
      <v-row
        v-if="$store.state.project.blocksLoading"
        :class="{ 'mt-10': cards }"
      >
        <v-col
          v-for="n in 6"
          :key="n"
          cols="2"
        >
          <v-skeleton-loader
            :type="cards ? 'card' : 'list-item'"
          />
        </v-col>
      </v-row>
      <v-breadcrumbs
        v-else-if="!cards"
        :items="breadcrumbs"
        class="justify-center fill-width"
        divider="•"
      />
      <v-row v-else class="mt-10">
      <v-col
        v-for="card in blocks || []"
        :key="card.id"
        cols="2"
      >
        <v-card
          style="min-height: 200px;"
          :to="{ name: card.to }"
        >
          <v-img
            v-if="card.image"
            :src="card.image.src"
            height="100"
            width="100%"
          />
          <v-card-title class="text-subtitle-1" style="word-break: break-word;">
            {{ card.title }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    </div>
    <slot />
  </v-col>
</template>

<script>
export default {
  name: 'Layout',
  props: {
    cards: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blocks () {
      return this.$store.getters['project/areaBlocks'] || []
    },
    breadcrumbs () {
      return [
        {
          text: 'Главная',
          to: { name: 'ProjectMain' },
          exact: true,
          disabled: this.$route.name === 'ProjectMain'
        },
        ...this.blocks.map(i => ({
          text: i.title,
          to: { name: i.to },
          disabled: this.$route.name === i.to,
          exact: true
        }))
      ]
    }
  }
}
</script>

<style scoped>

</style>
