<template>
  <area-documents-layout url="/api/project/bases">
    <template #before>
      <h3 class="text-h5 mt-6 mb-3">Площадки реализации</h3>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          offset-sm="3"
          class="text-center"
        >
          <v-text-field
            v-model="search"
            label="Поиск площадки по названию или контактам"
            prepend-icon="mdi-magnify"
            clearable
          />
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <template v-if="basesLoading">
          <v-col
            v-for="n in 4"
            :key="n"
            cols="3"
          >
            <v-skeleton-loader type="card" />
          </v-col>
        </template>
        <template v-else-if="bases.length">
          <template v-if="filteredBases.length">
            <v-col
              v-for="base in filteredBases || []"
              :key="base.id"
              cols="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  class="ma-1 transition-elevation"
                  style="cursor: pointer; min-height: 200px;"
                  :elevation="hover ? 15 : 3"
                  :class="hover ? 'mt-1' : 'mt-3'"
                  @click="openBaseDialog(base)"
                >
                  <v-card-title
                    v-text="base.name"
                    style="word-break: normal;"
                    class="text-subtitle-1"
                  />
                  <v-card-text>
                    <template v-if="(base.contacts || []).length">
                      <div
                        v-for="contact in base.contacts"
                        :key="contact.email"
                      >
                        <a :href="`mailto:${contact.email}`">{{ contact.name }}</a>
                      </div>
                    </template>
                    <p v-else>Нет контактов</p>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </template>
          <v-col
            v-else
            cols="12"
          >
            <p>Нет площадок, удовлетворяющих строке поиска.</p>
          </v-col>
        </template>
        <v-col v-else cols="12">
          <p>Нет площадок.</p>
        </v-col>
        <v-dialog
          v-model="baseDialog"
          :max-width="900"
          scrollable
        >
          <v-card>
            <v-card-title>
              <h5 class="text-h5">{{ (baseSelected || {}).name || '' }}</h5>
              <v-spacer />
              <v-btn icon @click="baseDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-list
                v-if="((baseSelected || {}).contacts || []).length"
                dense
                two-line
                rounded
              >
                <v-list-item
                  v-for="item in baseSelected.contacts"
                  :key="item.name"
                  @click="() => {}"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                    <v-list-item-subtitle>
                      <a :href="`mailto:${item.email}`" target="_blank">{{ item.email }}</a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p v-else>
                Нет контактов.
              </p>
              <documents-tree
                :documents="(baseSelected || {}).documents || []"
                :view-url="viewUrl"
                :download-url="downloadUrl"
                :open-url="openUrl"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="baseDialog = false">
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </area-documents-layout>
</template>

<script>
import AreaDocumentsLayout from '@/views/project/layouts/AreaDocumentsLayout'
import DocumentsTree from '@/components/Document/DocumentsTree'

export default {
  name: 'Bases',
  components: { DocumentsTree, AreaDocumentsLayout },
  data: () => ({
    basesLoading: false,
    bases: [],
    search: null,
    baseSelected: null,
    baseDialog: false,
    viewUrl: '',
    thumbnailUrl: '',
    downloadUrl: '',
    openUrl: ''
  }),
  computed: {
    areaId () {
      return this.$store.getters['project/areaId']
    },
    filteredBases () {
      const s = this.search || null
      const b = this.bases
      if (!s) {
        return b
      }
      const regEx = new RegExp(s.toLowerCase())
      return b.filter((item) => {
        return regEx.test(item.name.toLowerCase()) ||
          (item.contacts && item.contacts.some((i) => {
            return !!i && (regEx.test(i.name.toLowerCase()) || regEx.test(i.email.toLowerCase()))
          }))
      })
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    areaId () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      if (this.basesLoading) {
        return
      }
      this.basesLoading = true
      this.$axios.get('/api/project/bases/cards').then(({ data }) => {
        if (data.status !== 200) {
          throw new Error(data.message || 'Возникла непредвиденная ошибка')
        }
        this.viewUrl = data.url.view
        this.thumbnailUrl = data.url.thumbnail
        this.downloadUrl = data.url.download
        this.openUrl = data.url.open
        const bases = (data.items || [])[this.areaId] || []
        bases.forEach((base) => {
          base.documents = base.documents.map((item) => {
            if (!item.uniqueId) {
              return {
                ...item,
                uniqueId: item.name,
                isFolder: true
              }
            }
            return item
          })
        })
        this.bases = bases
      }).catch((err) => {
        this.bases = []
        console.error(err)
        this.$toasted.error(err.message)
      }).finally(() => {
        this.basesLoading = false
      })
    },
    openBaseDialog (base) {
      this.baseSelected = base || null
      this.baseDialog = true
    }
  }
}
</script>
